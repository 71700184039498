import { Map, List } from 'immutable';
import _ from 'lodash';
import moment from 'moment';

export default (
    state = Map({
        data: List(),
        dataCategory: List(),
        selectedNews: null
    }),
    action
) => {
    switch (action.type) {
        case 'LOAD_ALL_NEWS_CATEGORY':
            return state.set('dataCategory', List(action.data));
        case 'LOAD_ALL_NEWS':  
            // this is for testing pagination 
            // let tes = List(action.data);
            // for(let i=0; i<50; i++){
            //     tes = tes.concat(List(action.data)); 
            // }
            // action.data = tes.toJS();

            let categories = state.get('dataCategory');
            
            let news = _.map(action.data, row => {
                row.date = moment(row.createdDate).format('DD MMM YYYY');
                
                const c = categories.find(o => o.key === row.idCategory);
                if(c){
                    if(c.isPublish) row.category = c.name;
                }
                return row;
            })
        
            return state.set('data', List(news));
        case 'LOAD_NEWS':
            return state.set('selectedNews', action.data);
        default:
            return state;
    }
};