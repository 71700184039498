import { Map, List } from 'immutable';
import _ from 'lodash';
import moment from 'moment';

export default (
    state = Map({
        data: List(),
        dataCategory: List()
    }),
    action
) => {
    switch (action.type) {
        case 'LOAD_ALL_GALLERY_CATEGORY':
            return state.set('dataCategory', List(action.data));
        case 'LOAD_ALL_GALLERY':
            
            // this is for testing pagination 
            // let tes = List(action.data);
            // for(let i=0; i<50; i++){
            //     tes = tes.concat(List(action.data)); 
            // }
            // action.data = tes.toJS();

            let categories = state.get('dataCategory');
            
            let galleries = _.map(action.data, row => {
                const albumDate = row.albumDate.split('/');
                row.albumDate = `${albumDate[2]}-${albumDate[1]}-${albumDate[0]}`;
                row.date = moment(row.albumDate).format('DD MMM YYYY');
            
                row.medias = _.orderBy(row.medias, ['isVideo'], ['asc']);
                row.medias = _.map(row.medias, rowMedia => {
                    if(rowMedia.isVideo && rowMedia.isExternalSource){
                        let youtube_video_id = rowMedia.videoURL.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();

                        if (youtube_video_id.length === 11) {
                            rowMedia.poster = 'https://img.youtube.com/vi/'+youtube_video_id+'/0.jpg';
                        }
                    }

                    return rowMedia;
                });

                const c = categories.find(o => o.key === row.idCategory);
                if(c){
                    if(c.isPublish) row.category = c.name;
                }
                return row;
            })
            
            return state.set('data', List(galleries));
        default:
            return state;
    }
};