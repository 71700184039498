import { combineReducers } from 'redux';

// Import your reducers here...
import AgentData from './agent';
import UserData from './user';
import Cities from './cities';
import Roadshow from './roadshow';
import Talkshow from './talkshow';
import Provinces from './provinces';
import Landing from './landing';
import Gallery from './gallery';
import News from './news';

export default combineReducers({
    AgentData,
    UserData,
    Cities,
    Roadshow,
    Talkshow,
    Provinces,
    Landing,
    Gallery,
    News
});
