import { Map, List } from 'immutable';

export default (
    state = Map({
        data: List(),
        isVoted: false,
        isVoting: false,
        votedID: '',
        currentData: []
    }),
    action
) => {
    switch (action.type) {
        case 'SHOW_LOADER':
            return state.set('isVoting', true);
        case 'INCREASE_VOTE':
            const newData = state.get('currentData').map(row => {
                if (row.key === action.id) row.totalVote = action.totalVote + 1;
                return row;
            });
            return state.set('currentData', newData).set('isVoting', false);
        case 'UPDATE_CURRENT_VOTE_DATA':
            return state.set('currentData', action.data);
        case 'USER_HAS_VOTED':
            return state.set('isVoted', true).set('votedID', action.uid);
        case 'LOAD_LANDING_TALKSHOW':
            return state.set('data', List(action.data));
        default:
            return state;
    }
};
